import React, { Component } from "react";
import {
  SiCsharp,
  SiJavascript,
  SiPostman,
  SiVisualstudio,
  SiVisualstudiocode,
  SiWindowsxp,
  SiWindows,
  SiVisa,
  SiStackoverflow,
  SiSpotify,
  SiSlack,
  SiRss,
  SiReact,
  SiPython,
  SiPowershell,
  SiPostgresql,
  SiNuget,
  SiNpm,
  SiNodeDotJs,
  SiNginx,
  SiMysql,
  SiMongodb,
  SiMicrosoftaccess,
  SiMicrosoftexcel,
  SiMicrosoftoutlook,
  SiMastercard,
  SiLinkedin,
  SiLighthouse,
  SiKibana,
  SiJquery,
  SiJenkins,
  SiHtml5,
  SiGithub,
  SiGimp,
  SiElasticstack,
  SiElasticsearch,
  SiElasticcloud,
  SiElastic,
  SiDocker,
  SiDiscover,
  SiDell,
  SiCss3,
  SiCheckmarx,
  SiBootstrap,
  SiApache,
  SiAmazonaws,
  SiAmericanexpress,
} from "react-icons/si";
import { GiCctvCamera } from "react-icons/gi";
var data = require("./gxp");

class SkillImages extends Component {
  render() {
    switch (this.props.iconName) {
      case "GiCctvCamera":
        return <GiCctvCamera size="50" />;
      case "SiAmericanexpress":
        return <SiAmericanexpress size="50" />;
      case "SiCsharp":
        return <SiCsharp size="50" />;
      case "SiJavascript":
        return <SiJavascript size="50" />;
      case "SiPostman":
        return <SiPostman size="50" />;
      case "SiVisualstudio":
        return <SiVisualstudio size="50" />;
      case "SiVisualstudiocode":
        return <SiVisualstudiocode size="50" />;
      case "SiWindowsxp":
        return <SiWindowsxp size="50" />;
      case "SiWindows":
        return <SiWindows size="50" />;
      case "SiVisa":
        return <SiVisa size="50" />;
      case "SiStackoverflow":
        return <SiStackoverflow size="50" />;
      case "SiSpotify":
        return <SiSpotify size="50" />;
      case "SiSlack":
        return <SiSlack size="50" />;
      case "SiRss":
        return <SiRss size="50" />;
      case "SiReact":
        return <SiReact size="50" />;
      case "SiPython":
        return <SiPython size="50" />;
      case "SiPowershell":
        return <SiPowershell size="50" />;
      case "SiPostgresql":
        return <SiPostgresql size="50" />;
      case "SiNuget":
        return <SiNuget size="50" />;
      case "SiNpm":
        return <SiNpm size="50" />;
      case "SiNodeDotJs":
        return <SiNodeDotJs size="50" />;
      case "SiNginx":
        return <SiNginx size="50" />;
      case "SiMysql":
        return <SiMysql size="50" />;
      case "SiMongodb":
        return <SiMongodb size="50" />;
      case "SiMicrosoftaccess":
        return <SiMicrosoftaccess size="50" />;
      case "SiMicrosoftexcel":
        return <SiMicrosoftexcel size="50" />;
      case "SiMicrosoftoutlook":
        return <SiMicrosoftoutlook size="50" />;
      case "SiMastercard":
        return <SiMastercard size="50" />;
      case "SiLinkedin":
        return <SiLinkedin size="50" />;
      case "SiLighthouse":
        return <SiLighthouse size="50" />;
      case "SiKibana":
        return <SiKibana size="50" />;
      case "SiJquery":
        return <SiJquery size="50" />;
      case "SiJenkins":
        return <SiJenkins size="50" />;
      case "SiHtml5":
        return <SiHtml5 size="50" />;
      case "SiGithub":
        return <SiGithub size="50" />;
      case "SiGimp":
        return <SiGimp size="50" />;
      case "SiElasticstack":
        return <SiElasticstack size="50" />;
      case "SiElasticsearch":
        return <SiElasticsearch size="50" />;
      case "SiElasticcloud":
        return <SiElasticcloud size="50" />;
      case "SiElastic":
        return <SiElastic size="50" />;
      case "SiDocker":
        return <SiDocker size="50" />;
      case "SiDiscover":
        return <SiDiscover size="50" />;
      case "SiDell":
        return <SiDell size="50" />;
      case "SiCss3":
        return <SiCss3 size="50" />;
      case "SiCheckmarx":
        return <SiCheckmarx size="50" />;
      case "SiBootstrap":
        return <SiBootstrap size="50" />;
      case "SiApache":
        return <SiApache size="50" />;
      case "SiAmazonaws":
        return <SiAmazonaws size="50" />;
    }
  }
}

export default SkillImages;
