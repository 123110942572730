import React, { Component } from "react";
import { Container, Header, Divider, Segment, Image } from "semantic-ui-react";
import SkillImages from "./SkillImages";
import andrickn from "./images/andrickn.png";
import littleLinus from "./images/LittleLinus.png";
import Spacing from "./Spacing";
import { FaBeer } from 'react-icons/fa';
var data = require("./gxp");
//const margin = "style='margin-bottom: 150px;'";

class Experience extends Component {
  render() {
    return (
      <Container>
        <Image src={andrickn} size="medium" circular centered />
        {data.contactInformation.map((s) => (
          <ul>
            <li>Me: {s.Name}</li>
            <li>
              Phone IVR:
              <a href="tel:402-415-0330"> {s.Phone}</a>
            </li>
            <li>Email: <a href={s.Email}>LinkedIn Profile</a></li>
            <li>{s.Site}</li>
            <li>{s.Description}</li>
          </ul>
        ))}
        <br />
        <br />
        <div>
          {data.jobs.map((job, i) => {
            return (
              <div key={i}>
                <div>
                  <Segment inverted color="black">
                    <h1>
                      {job.employer} - {job.location}
                    </h1>
                    <h2>
                      {job.started} - {job.ended}
                    </h2>
                    <h3>({job.totalTime})</h3>
                    <Spacing />
                  </Segment>
                  <Segment>
                    {job.positions.map(function (position, i) {
                      return (
                        <div key={i}>
                          <Header as="h2" floated="left">
                            {position.title}
                          </Header>
                          <Header as="h5" floated="right">
                            {position.totalTime}
                          </Header>
                          <Container textAlign="justified">
                            <Divider clearing />
                            {position.resumeSections.map(function (section, i) {
                              return (
                                <div key={i}>
                                  <b>{section.headerTitle}</b>
                                  {section.bulletSections.map(function (
                                    items,
                                    i
                                  ) {
                                    return (
                                      <div key={i}>
                                        <p>{items}</p>
                                      </div>
                                    );
                                  })}
                                  <Container>
                                    <br />
                                    {section.skillImageLinks.map(function (
                                      skills,
                                      i
                                    ) {
                                      return <SkillImages iconName={skills} />;
                                    })}
                                  </Container>
                                </div>
                              );
                            })}

                            <br />
                            <br />
                          </Container>
                        </div>
                      );
                    })}
                  </Segment>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            );
          })}
        </div>
        <Image src={littleLinus} size="medium" circular centered />
        <br />
        Totally going to turn this into a carousel of images for this dude (I've
        heard recruiters love dog images).
        <br />
        <br />
        Built from "scratch" - React, Json file for content, semantic-ui-react
        for styling (might swap here soon), react-icons for icons, Ben and Judy
        Andrick for creating the png of me and instilling a tenacious drive to
        keep pushing through in all endeavors. Stay posted, ya might see links
        to Big Brother Ben (o.o), Vigilant Big Sis Courtney, and Little Dr.
        Sister Kelly down the road.
      </Container>
    );
  }
}

export default Experience;
